import React from "react";
import GlobalStyles from "../styles/GlobalStyles";
import Header from "../components/Header";
import Contact from "../sections/Contact";

const ContactPage = () => {
  return (
    <>
      {" "}
      <GlobalStyles />
      <Header heroTitle="Get in touch" />
      <Contact />
    </>
  );
};

export default ContactPage;
